import React, { useState, useEffect, useContext, useCallback } from 'react'
import styled from 'styled-components'
import Router, { useRouter } from 'next/router'
import { toast } from 'react-toastify'
import { destroyCookie } from 'nookies/dist'
import { useSearchUrlWithContractId, useOutsideClick } from 'hooks'

import { HeaderNavbar } from 'components/v2/Header'
import { Column, Row } from 'components/v2/Grid'
import { Text } from 'components/v2/Text'
import { NextImage } from 'components/v2/NextImage'
import { Button } from 'components/v2/Button'
import { TimezoneSelect } from 'components/v2/TimezoneSelect'
import {
  UserCircle,
  CalendarBlank,
  BookmarkSimple,
  Gift,
  Question,
  CaretDown,
  CaretUp
} from 'phosphor-react'
import { BookmarkTag, WorldGlobeTimeZone } from 'components/v2/Icons'

import FavoriteContext from 'context/favoriteContext'
import { logout, getProfile, getToken, getRefreshToken } from 'services/auth'
import { getCartBalance } from 'services/shoppingCart'
import { deleteDataContractId } from 'utils/helpers'
import ChangeAvatarModal from './ChangeAvatarModal'
import { useFeatureFlags } from 'providers/FeatureFlagsProvider'
import { useFeatureFlags as featureFlagHook } from 'hooks/useFeatureFlags'
import { useBeaconIncidents } from 'hooks'
import { Beacon } from './Beacon'
import { sendBeaconIncidentsListEvent } from 'components/shared/DataLayer'
import TimezoneContext from 'context/timezoneContext'

const HomeHeader = ({ openModal }) => {
  const { frontend_beacon_platform_feature } = featureFlagHook('frontend_beacon_platform_feature')

  const { getFeatures } = useFeatureFlags()

  const [user, setUser] = useState(null)
  const [balance, setBalance] = useState(null)
  const [navColor, setNavColor] = useState('transparent')
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [isProfessional, setIsProfessional] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [psychologistMenu, setPsychologistMenu] = useState(false)
  const [beaconMenu, setBeaconMenu] = useState(false)
  const [isTimeZoneDropdownOpen, setIsTimeZoneDropdownOpen] = useState(false)

  const { pathname } = useRouter()
  const { likeProfessionals } = useContext(FavoriteContext)
  const { getSearchUrlWithContractId } = useSearchUrlWithContractId()

  const listenScrollEvent = () => {
    window.scrollY > 10 ? setNavColor('#ffffff') : setNavColor('transparent')
  }

  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent)

    return () => {
      window.removeEventListener('scroll', listenScrollEvent)
    }
  }, [])

  const profile = getProfile()
  const refreshToken = getRefreshToken()
  const token = getToken()
  const userIsProfessional = profile && profile?.role === 'professional'
  const userIsLogged = profile && refreshToken && token
  const { timezone } = useContext(TimezoneContext)
  const { data } = useBeaconIncidents(userIsLogged, timezone, userIsProfessional)

  const handleBalance = useCallback(async () => {
    try {
      const { data } = await getCartBalance(profile.billing_account_id)
      setBalance(data?.content?.balance)
    } catch (error) {
      if (error?.response?.status === 401) {
        setUser(null)
        setIsUserLoggedIn(false)
        setIsProfessional(false)
      }
      console.log(error)
    }
  }, [profile])

  useEffect(async () => {
    if (userIsLogged) {
      setUser(profile)
      setIsUserLoggedIn(true)
    }

    if (userIsLogged && userIsProfessional) {
      setIsProfessional(true)
    }
  }, [])

  useEffect(() => {
    if (isDropdownOpen && userIsLogged && profile?.billing_account_id && !balance) handleBalance()
  }, [isDropdownOpen])

  const handleLogOut = async () => {
    try {
      getFeatures()
      await logout()

      destroyCookie(null, 'contractId')
      deleteDataContractId()
      setIsUserLoggedIn(false)
      setIsProfessional(false)
      setIsDropdownOpen(false)

      toast.success('Você saiu da conta.')
    } catch (error) {
      console.log(error)
    } finally {
      Router.push({
        pathname: '/'
      })
    }
  }

  const handleClickOutside = e => {
    setIsTimeZoneDropdownOpen(false)
    setBeaconMenu(false)
  }

  const handleDropdownMenuWrapperClickOutside = () => {
    setIsDropdownOpen(false)
  }

  const handleBeaconClick = () => {
    if (beaconMenu === false && data?.beaconsIncidents) {
      const incidentsList = data?.beaconsIncidents?.map(incident => ({
        title: incident?.title,
        state: incident?.type
      }))
      sendBeaconIncidentsListEvent(incidentsList)
    }
    setBeaconMenu(!beaconMenu)
  }

  const ref = useOutsideClick(handleClickOutside)

  const dropdownMenuWrapperRef = useOutsideClick(handleDropdownMenuWrapperClickOutside)

  const visitorsMenuClick = e => {
    e.stopPropagation()
  }

  return (
    <>
      {isProfessional ? (
        <NavbarBackground id='header-nav-bar' backgroundColor='#1D4E91'>
          <Row position='relative' justifyContent='center' width='100%' maxWidth='1100px'>
            <HeaderContent>
              <ProfessionalLogoVittude>
                <NextImage
                  src='/static/images/logo-vittude-white.svg'
                  alt='vittude-logo'
                  title='Logo da Vittude'
                  width={140}
                  height={42}
                />
                <Text
                  textAlign='end'
                  letterSpacing='3px'
                  lineHeight='10px'
                  fontSize='14px'
                  color='white'
                >
                  PSICÓLOGO
                </Text>
              </ProfessionalLogoVittude>
              <Row gridColumnGap='30px'>
                {frontend_beacon_platform_feature ? (
                  <Row position='relative' ref={ref}>
                    <ButtonStatus onClick={handleBeaconClick}>
                      <NextImage
                        src={data?.icon || '/static/images/psicologo-sem-foto.png'}
                        width={20}
                        height={20}
                      />
                      <Text variant='regular' color='white' textAlign='left'>
                        Status da plataforma
                      </Text>
                    </ButtonStatus>

                    <Beacon
                      isActive={beaconMenu}
                      icon={data?.icon || '/static/images/psicologo-sem-foto.png'}
                      havingIncidents={data?.isHavingIncidents}
                      incidents={data?.beaconsIncidents}
                    />
                  </Row>
                ) : (
                  <></>
                )}

                <Row borderRadius='50%' border='3px solid' borderColor='primary'>
                  <Row bg='secondary' borderRadius='50%' border='2px solid' borderColor='white'>
                    <HeaderNavThumbImg justifyContent='center'>
                      <NextImage
                        src={user?.social_thumb || '/static/images/psicologo-sem-foto.png'}
                        width={36}
                        height={36}
                        border='1px solid !important'
                        borderRadius='50%'
                        onClick={() => setPsychologistMenu(!psychologistMenu)}
                        objectFit='cover'
                      />
                    </HeaderNavThumbImg>
                  </Row>
                </Row>
              </Row>
            </HeaderContent>

            <PsychologistsMenu active={psychologistMenu}>
              <Row width='260px' justifyContent='space-around'>
                <Column
                  marginRight='30px'
                  marginTop='5px'
                  alignItems='center'
                  justifyContent='center'
                  onClick={() => setIsModalOpen(true)}
                >
                  <NextImage
                    src={user?.social_thumb || '/static/images/psicologo-sem-foto.png'}
                    width={80}
                    height={80}
                    borderRadius='50%'
                    objectFit='cover'
                  />
                  <Text
                    fontWeight='600'
                    fontSize='13px'
                    color='primary'
                    lineHeight='30px'
                    textAlign='left'
                  >
                    Alterar foto
                  </Text>
                </Column>
                <Column marginRight='30px' marginTop='5px'>
                  <Text
                    fontWeight='600'
                    fontSize='18px'
                    color='darkBlue'
                    lineHeight='30px'
                    textAlign='left'
                  >
                    {user?.name.split(' ')[0]}
                  </Text>
                  <PsychologistsMenuItem onClick={() => Router.push('/painel?menu=meus-dados')}>
                    Meus dados
                  </PsychologistsMenuItem>
                  <PsychologistsMenuItem onClick={handleLogOut}>Sair</PsychologistsMenuItem>
                </Column>
              </Row>
            </PsychologistsMenu>
          </Row>
        </NavbarBackground>
      ) : (
        <NavbarBackground id='header-nav-bar' backgroundColor={navColor}>
          <HeaderContent>
            <Row>
              <LogoVittude>
                <NextImage
                  src='/static/images/logo-vittude.svg'
                  alt='vittude-logo'
                  title='Logo da Vittude'
                  height={50}
                  width={130}
                  onClick={() => {
                    localStorage.setItem('resetSteps', 'true')
                    Router.push('/')
                  }}
                  priority
                />
              </LogoVittude>

              <SectionsMenu>
                {isUserLoggedIn ? (
                  <>
                    <SectionsItem
                      isActive={pathname === '/busca'}
                      onClick={() => Router.push(getSearchUrlWithContractId())}
                    >
                      Encontre seu psicólogo
                    </SectionsItem>
                    <SectionsItem
                      isActive={pathname === '/encontre-seu-psicologo'}
                      onClick={() => Router.push('/encontre-seu-psicologo')}
                    >
                      Vittude Match
                    </SectionsItem>
                  </>
                ) : (
                  <>
                    <SectionsItem
                      isActive={pathname === '/faq'}
                      onClick={() => Router.push('/faq')}
                    >
                      Como funciona
                    </SectionsItem>
                    <SectionsItem
                      isActive={pathname === '/sou-psicologo'}
                      onClick={() => Router.push('/sou-psicologo')}
                    >
                      Para psicólogos
                    </SectionsItem>
                    <SectionsItem
                      isActive={pathname === '/empresas'}
                      onClick={() => Router.push('/empresas')}
                    >
                      Para empresas
                    </SectionsItem>
                  </>
                )}

                <SectionsItem isActive={pathname === '/blog'} onClick={() => Router.push('/blog')}>
                  Blog
                </SectionsItem>
              </SectionsMenu>
            </Row>

            <Row>
              <VisitorsMenu>
                <DropdownRow>
                  <SectionsItem color='darkPrimary' onClick={visitorsMenuClick}>
                    {isUserLoggedIn ? (
                      <Text
                        color='primary'
                        fontWeight='400'
                        variant='regular'
                        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                      >
                        Olá,{' '}
                        {isProfessional
                          ? user?.first_name?.split(' ')[0]
                          : user?.name?.split(' ')[0]}{' '}
                      </Text>
                    ) : (
                      <>
                        {pathname === '/' && (
                          <Button
                            variant='primary-outlined'
                            borderColor='#E7EAF4'
                            color='black'
                            height='42px'
                            fontWeight='600'
                            fontSize='14px'
                            mr='3'
                            onClick={() => Router.push('/ativar-beneficio')}
                          >
                            Ativar benefício
                          </Button>
                        )}
                        <Button
                          variant='primary-refit'
                          borderColor='primary'
                          height='42px'
                          fontWeight='600'
                          fontSize='14px'
                          onClick={() => Router.push('/entrar')}
                        >
                          Entrar
                        </Button>
                      </>
                    )}
                  </SectionsItem>
                  <Row alignItems='center'>
                    {isUserLoggedIn && (
                      <>
                        {isDropdownOpen ? (
                          <CaretUp
                            size={24}
                            color='#FF7708'
                            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                          />
                        ) : (
                          <CaretDown
                            size={24}
                            color='#FF7708'
                            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                          />
                        )}
                      </>
                    )}
                    <DropdownMenuWrapper ref={dropdownMenuWrapperRef} active={isDropdownOpen}>
                      {isUserLoggedIn && (
                        <Column>
                          <Text color='gray.n400' mt='4px' fontSize='14px'>
                            Que bom ter você de volta! (:
                          </Text>
                          <Row
                            borderBottom='2px solid'
                            borderColor='gray.n200'
                            width='216px'
                            my='18px'
                          />
                          <Text color='gray.n400' fontSize='12px' mb='9px'>
                            Atalhos
                          </Text>
                          <Row my='9px'>
                            <DropdownLink
                              px='8px'
                              onClick={() => Router.push('/painel/minhas-consultas')}
                            >
                              <CalendarBlank size={22} weight='regular' />
                              Minhas consultas
                            </DropdownLink>
                          </Row>
                          <Row my='9px'>
                            <DropdownLink
                              px='8px'
                              onClick={() => Router.push('/painel/meus-dados')}
                            >
                              <UserCircle size={22} weight='regular' />
                              Minha conta
                            </DropdownLink>
                          </Row>
                          <Row my='9px'>
                            <DropdownLink px='8px' onClick={() => Router.push('/favoritos')}>
                              <BookmarkSimple size={22} weight='regular' />
                              Psicólogos favoritos
                            </DropdownLink>
                          </Row>
                          {user.referrer && (
                            <Row my='9px'>
                              <DropdownLink
                                px='8px'
                                onClick={() => Router.push('/painel/convidar-amigos')}
                              >
                                <Gift size={22} weight='regular' />
                                Ganhe R$ {user.referrer?.referral_value} reais
                              </DropdownLink>
                            </Row>
                          )}

                          <Row mt='9px' mb='18px'>
                            <DropdownLink
                              px='8px'
                              onClick={() => Router.push('https://meajuda.vittude.com/pacientes')}
                            >
                              <Question size={22} weight='regular' />
                              Ajuda
                            </DropdownLink>
                          </Row>
                          <SeparatorLine />
                          <Column mt='18px'>
                            {!!balance && balance !== 0 && (
                              <Column>
                                <Text color='gray.n400' fontSize='12px' lineHeight='24px'>
                                  Saldo
                                </Text>
                                <Text color='#168F49' lineHeight='24px'>
                                  R$ {balance}
                                </Text>
                                <SeparatorLine my='18px' />
                              </Column>
                            )}
                            <Text
                              color='gray.n400'
                              fontSize='12px'
                              textDecoration='underline'
                              lineHeight='24px'
                              onClick={handleLogOut}
                            >
                              Sair da conta
                            </Text>
                          </Column>
                        </Column>
                      )}
                    </DropdownMenuWrapper>
                  </Row>
                </DropdownRow>
                <Row position='relative' ref={ref}>
                  <DropdownRow
                    onClick={() => setIsTimeZoneDropdownOpen(!isTimeZoneDropdownOpen)}
                    ml='30px'
                  >
                    <WorldGlobeTimeZone />
                  </DropdownRow>
                  {isTimeZoneDropdownOpen && <TimezoneSelect />}
                </Row>
              </VisitorsMenu>
            </Row>
          </HeaderContent>
        </NavbarBackground>
      )}
      <ChangeAvatarModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
    </>
  )
}

const NavbarBackground = styled(HeaderNavbar)`
  padding: 18px 0;
  width: 100%;
  justify-content: center;
  z-index: 999;

  @media (max-width: 640px) {
    min-height: 48px;
    background: #efeeee;
  }
`

const HeaderContent = styled(Row)`
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  padding-right: 5px;
  padding-left: 5px;
`

const SectionsMenu = styled(Row)`
  align-items: center;
`

const SectionsItem = styled(Row)`
  ${({ theme: { colors }, isActive }) => `
  justify-content: center;
  font-family: Sora;
  font-size: 16px;
  font-weight: 400;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  color: ${isActive && colors.primary};
  font-weight: ${isActive && 600};

  &:hover {
    color: #FF7708;
  }
  `}
`

const Separator = styled(Row)`
  transform: rotate(-90deg);
  border-bottom: 2px solid #f3f3f3;
  width: 32px;
`

const SeparatorLine = styled(Row)`
  border-bottom: 2px solid;
  border-color: #f3f3f3;
  width: 216px;
`

const VisitorsMenu = styled(Row)`
  align-items: center;
`

const DropdownRow = styled(Row)`
  cursor: pointer;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  @keyframes float {
    0% {
      transform: translatey(0px);
    }
    50% {
      transform: translatey(-3px);
    }
    100% {
      transform: translatey(0px);
    }
  }

  ${Row} > svg {
    animation: float 2s ease-in-out infinite;
  }
`

const DropdownLink = styled(Text)`
  ${({ theme: { colors } }) => `
  font-family: Fira Sans;
  font-size: 16px;
  font-weight: 400;
  padding-left: 0;
  padding-right: 8px;

  display: flex;
  align-items: center;
  gap: 8px;

  cursor: pointer;
  transition: 0.2s color ease-in-out;

  &:hover {
    color: ${colors.primary};
  }

  }`}
`

const DropdownMenuWrapper = styled(Row)`
  ${({ theme: { colors }, active }) => `
    z-index: 999;
    position: absolute;
    top: 30px;
    right: 1px;
    background: #ffffff;
    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    width: 255px;
    padding: 20px;
    text-align: left;
    opacity: ${active === true ? '1' : '0'};
    visibility: ${active === true ? 'visible' : 'hidden'};
    transition: 0.3s all;
  `}
`

const LogoVittude = styled(Column)`
  cursor: pointer;
  margin-right: 40px;
`

const ProfessionalLogoVittude = styled(Column)`
  margin-right: 40px;
`

const HeaderNavThumbImg = styled(Row)`
  position: relative;

  img {
    border-radius: 100%;
  }
`

const PsychologistsMenu = styled(Row)`
  ${({ theme: { colors }, active }) => `
  position: absolute;
  top: 60px;
  right: 0;
  padding: 20px;
  border-radius: 6px;
  background-color: #f5f5f5;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 24%);

  opacity: ${active === true ? '1' : '0'};
  visibility: ${active === true ? 'visible' : 'hidden'};
  transition: 0.3s all;

  @media (max-width: 1024px) {
    right: 5px;
  }
  `}
`

const PsychologistsMenuItem = styled(Row)`
  font-weight: 600;
  font-size: 15px;
  color: #29374d;
  line-height: 20px;
  cursor: pointer;

  &:hover {
    color: #ff7708;
  }
`

const ButtonStatus = styled(Row)`
  ${({ theme: { colors }, active }) => `
    background:#17233e;
    padding:9px 12px;
    border-radius: 10px;
    align-self: center;
    cursor: pointer;
    gap: 0 15px;
    transition: 0.3s all;
    font-weigth: 500;
    max-height: 40px;

    &:hover {
      background: #081124;
    }

    @media (max-width: 640px) {
      min-height: 48px;
      background: #efeeee;
    }
`}
`

export { HomeHeader }
