import React, { useState, useEffect, useContext, useMemo } from 'react'
import styled from 'styled-components'
import Router from 'next/router'
import {
  BookmarkTag,
  CalendarIcon,
  QuestionMarkCircle,
  UserAccountCircle,
  GiftIcon,
  WorldGlobeTimeZone,
  Monthplan,
  MenuBenefits,
  MenuComment
} from '../Icons'
import { Column, Row } from '../Grid'
import { Text } from '../Text'
import { NextImage } from '../NextImage'
import { Button } from '../Button'
import { SectionsItemMobile } from './SectionsItemMobile'
import { TimezoneSelect } from '../TimezoneSelect'
import { useOutsideClick } from 'hooks'
import { getMonthplan } from 'services/monthplan'
import TimezoneContext from 'context/timezoneContext'
import { getNewestCheckout, isExpired } from 'utils/helpers'

const DropdownMenuItems = ({
  openDropdown,
  isUserLoggedIn,
  isProfessional,
  handleLogOut,
  balance,
  user,
  openDropdownMenu,
  setOpenDropdownMenu,
  openModal
}) => {
  const [isTimeZoneDropdownOpen, setIsTimeZoneDropdownOpen] = useState(false)
  const [isTimeZoneOpen, setIsTimeZoneOpen] = useState(false)
  const [monthplanData, setMonthplanData] = useState(null)
  const { timezone } = useContext(TimezoneContext)

  const handleMenuClick = route => {
    setOpenDropdownMenu(!openDropdownMenu)
    Router.push(route)
  }

  const handleClickOutside = e => {
    setIsTimeZoneDropdownOpen(false)
  }

  const ref = useOutsideClick(handleClickOutside)

  const dropdownMenuWrapperClick = e => {
    e.stopPropagation()
  }

  useEffect(() => {
    ;(async () => {
      if (user?.role === 'patient' && user?.is_month_plan_available) {
        try {
          const { data } = await getMonthplan(timezone)
          setMonthplanData(data)
        } catch (error) {
          console.log(error)
        }
      }
    })()
  }, [timezone])

  const checkingMonthplanAvailable = useMemo(() => {
    const newestCheckout = getNewestCheckout(monthplanData?.checkouts || [])

    const hasConsultationNotPaid =
      !newestCheckout?.payments?.[0]?.paid &&
      newestCheckout?.consultations?.[0].status_display === 'awaiting_payment'

    const isAbandonedMonthplan =
      monthplanData?.checkouts?.[0]?.consultations[0]?.status_display === 'abandoned'

    const isExpiredDate =
      !newestCheckout?.payments?.[0]?.paid && isExpired(newestCheckout?.payments?.[0]?.due_date)

    const isMonthPlanEnabledUser = user?.is_month_plan_available
    const hasMonthPlanInvalid = isAbandonedMonthplan || isExpiredDate
    const hasPendingPayment = isMonthPlanEnabledUser && hasConsultationNotPaid
    const hasMonthPlanAssinedAndValid = !!monthplanData?.checkouts && !hasMonthPlanInvalid

    return {
      isMonthPlanEnabledUser,
      hasMonthPlanInvalid,
      hasPendingPayment,
      hasMonthPlanAssinedAndValid
    }
  }, [monthplanData])

  const monthPlanDisplay =
    checkingMonthplanAvailable?.hasPendingPayment ||
    checkingMonthplanAvailable?.hasMonthPlanAssinedAndValid

  return (
    <DropdownMenuWrapper onClick={dropdownMenuWrapperClick}>
      {isTimeZoneOpen ? (
        <Row ref={ref}>
          <TimezoneSelect />
        </Row>
      ) : (
        <Column width='100%'>
          {!isUserLoggedIn && (
            <Column>
              <Column>
                <SectionsItemMobile label='Como funciona' route='/faq' />
                <SectionsItemMobile label='Para você' route='/' />
                <SectionsItemMobile label='Para psicólogos' route='/sou-psicologo' />
                <SectionsItemMobile label='Para empresas' route='/empresas' />
                <SectionsItemMobile label='Blog' route='/blog' />
                <Column p='20px'>
                  <Button
                    variant='secondary-outlined'
                    mt='24px'
                    height='56px'
                    borderColor='#E7EAF4'
                    color='#000'
                    onClick={openModal}
                  >
                    <Text fontWeight='600'>Ativar benefício</Text>
                  </Button>
                  <Button
                    variant='primary-refit'
                    borderColor='primary'
                    height='56px'
                    fontWeight='600'
                    my='16px'
                    onClick={() => Router.push('/entrar')}
                    type='submit'
                  >
                    Entrar
                  </Button>
                </Column>
                <Column p='20px'>
                  <Row onClick={() => setIsTimeZoneOpen(!isTimeZoneOpen)} alignItems='center'>
                    <WorldGlobeTimeZone color='#000' />
                    <Text ml='8px'>Configure seu fuso horário</Text>
                  </Row>
                </Column>
              </Column>
            </Column>
          )}
          {isUserLoggedIn && (
            <Column p='20px'>
              {!isProfessional ? (
                <Column alignItems='flex-start'>
                  <UserSection color='darkPrimary'>
                    <strong>
                      Olá,{' '}
                      {isProfessional ? user?.first_name.split(' ')[0] : user?.name.split(' ')[0]}{' '}
                    </strong>
                  </UserSection>
                  <Text color='gray.n400' mt='4px' fontSize='16px'>
                    Que bom ter você de volta!
                  </Text>
                  <Row borderBottom='2px solid' borderColor='gray.n200' width='216px' my='18px' />
                  <Row my='16px'>
                    <CalendarIcon />
                    <Text px='8px' onClick={() => handleMenuClick('/painel?menu=minhas-consultas')}>
                      Minhas consultas
                    </Text>
                  </Row>
                  {monthPlanDisplay && (
                    <Row my='16px'>
                      <Monthplan />
                      <Text
                        px='8px'
                        onClick={() => handleMenuClick('/painel?menu=meu-plano-de-consultas')}
                      >
                        Meu plano de consultas
                      </Text>
                    </Row>
                  )}
                  <Row my='16px'>
                    <UserAccountCircle />
                    <Text px='8px' onClick={() => handleMenuClick('/painel?menu=meus-dados')}>
                      Minha conta
                    </Text>
                  </Row>
                  <Row my='16px'>
                    <MenuComment />
                    <Text px='8px' onClick={() => handleMenuClick('/painel?menu=meus-comentarios')}>
                      Meus comentários
                    </Text>
                  </Row>
                  {user?.contract && (
                    <Row my='16px'>
                      <MenuBenefits />
                      <Text
                        px='8px'
                        onClick={() => handleMenuClick('/painel?menu=meus-beneficios')}
                      >
                        Meus Benefícios
                      </Text>
                    </Row>
                  )}
                  <Row my='16px'>
                    <BookmarkTag />
                    <Text px='8px' onClick={() => handleMenuClick('/favoritos')}>
                      Psicólogos favoritos
                    </Text>
                  </Row>
                  {user.referrer && (
                    <Row my='16px'>
                      <GiftIcon />
                      <Text px='8px' onClick={() => Router.push('/painel/convidar-amigos')}>
                        Ganhe R$ {user.referrer?.referral_value} reais
                      </Text>
                    </Row>
                  )}
                  <Row
                    onClick={() => {
                      setIsTimeZoneDropdownOpen(!isTimeZoneDropdownOpen)
                      setIsTimeZoneOpen(!isTimeZoneOpen)
                    }}
                    my='16px'
                    alignItems='center'
                  >
                    <WorldGlobeTimeZone color='#000' />
                    <Text ml='8px'>Configure seu fuso horário</Text>
                  </Row>
                  <Row my='16px'>
                    <QuestionMarkCircle />
                    <Text
                      px='8px'
                      onClick={() => handleMenuClick('https://meajuda.vittude.com/pacientes')}
                    >
                      Ajuda
                    </Text>
                  </Row>

                  <SeparatorLine />
                  <Column mt='18px' alignItems='flex-start'>
                    <Text color='gray.n400' fontSize='12px' lineHeight='24px'>
                      Saldo
                    </Text>
                    <Text color='#168F49' lineHeight='24px'>
                      R$ {balance?.balance}
                    </Text>
                    <SeparatorLine my='18px' />
                    <Text
                      color='gray.n400'
                      fontSize='12px'
                      textDecoration='underline'
                      lineHeight='24px'
                      onClick={() => handleLogOut()}
                    >
                      Sair da conta
                    </Text>
                  </Column>
                </Column>
              ) : (
                <Column width='250px'>Psicologo logado</Column>
              )}
            </Column>
          )}
        </Column>
      )}
    </DropdownMenuWrapper>
  )
}

const DropdownMenuWrapper = styled(Row)`
  background: #ffffff;
  width: 100%;
  height: 100vh;
  z-index: 99999;
  position: absolute;
  left: 0;
  right: 0;
  top: 60px;
`

const SeparatorLine = styled(Row)`
  border-bottom: 2px solid;
  border-color: #f3f3f3;
  width: 256px;
`

const UserSection = styled(Row)`
  cursor: pointer;
  margin-bottom: 12px;
  font-size: 15px;

  &:hover {
    color: #ff7708;
  }
`

export { DropdownMenuItems }
